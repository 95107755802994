import { Box, Button, Divider, Typography } from "@mui/material"
import { Form, Formik, FormikHelpers, useField, useFormikContext } from "formik"
import { FC, useContext, useEffect, useState } from "react"
import { BiImage, BiNote, BiX } from "react-icons/bi"
import { useQuery } from "urql"
import * as Yup from "yup"
import {
  GetTasksForProjectReportingQuery,
  TaskReportInput,
  useCreateManyTaskReportsMutation,
} from "../graphql/generated/client-types-and-hooks"
import { graphql } from "../graphql/generated/gql"
import { taskReportImagePrefix } from "../helpers/files/s3UploadFilePrefixHelpers"
import { useHandleError } from "../hooks/useHandleError"
import { warnOnExitStoreActions } from "../stores/warnOnExit"
import { FilterDropdownButton } from "./FilterDropdownButton"
import { TextField } from "./Formik/TextField"
import { ImageUploadPreviewList } from "./ImageUploadPreviewList"
import { errorSnack, successSnack } from "./Notistack/ThemedSnackbars"
import { SingleDrawerContext } from "./Partials/Drawer/components/Elements/Drawer"
import { DrawerFooter } from "./Partials/Drawer/components/Elements/DrawerFooter"
import { DrawerHeader } from "./Partials/Drawer/components/Elements/DrawerHeader"
import { UnitGoalRow } from "./UnitGoalRow"

export const testLabel_ReportUnitCard = "report-unit-card"

type Props = {
  projectId: string
  taskId?: string
}

const TasksQuery = graphql(`
  query GetTasksForProjectReporting($projectId: String!) {
    project(id: $projectId) {
      id
      name

      contract {
        id
        name
        deliverableUnits {
          id
          deliverableUnitId
          customerDescription
        }
      }
    }
    tasks(projectId: $projectId, status: active) {
      id
      name
      isDefault
      group {
        id
        name
      }
      unitGoals {
        id
        targetQuantity
        totalProgress
        isPrimary
        deliverableUnit {
          id
          description
          unitOfMeasure
        }
      }
    }
  }
`)

type FileObject = { uploaded: boolean; fileId: string }

type UnitGoalInput = { unitGoalId: string; progress: number }

type TaskReport = {
  taskId: string
  note?: string
  files?: FileObject[]
  unitGoals?: UnitGoalInput[]
}

type ProjectUnitReportValues = {
  projectId: string
  taskReports: TaskReport[]
}

export const ProjectUnitReportForm: FC<Props> = ({ projectId, taskId }) => {
  const [_, createManyTaskReports] = useCreateManyTaskReportsMutation()

  const [{ data: taskData, fetching: tasksLoading, error: tasksError }, _refetchTasks] = useQuery({
    query: TasksQuery,
    variables: { projectId },
  })

  const tasks = (taskData?.tasks || []).filter(({ isDefault }) => !isDefault)
  const subtasks = tasks.filter((taskList) => taskList.group?.id === taskId)

  useHandleError(tasksError, "Could not fetch tasks")

  const [filteredTasks, setFilteredTasks] = useState<string[]>([])
  const [unFilteredTasks, setUnFilteredTasks] = useState<GetTasksForProjectReportingQuery["tasks"]>([])

  const { handleClose } = useContext(SingleDrawerContext)
  const { setHasUnsavedChanges } = warnOnExitStoreActions
  const [_isDirty, setIsDirty] = useState(false)

  useEffect(() => {
    if (filteredTasks.length === 0) {
      if (Boolean(taskId)) {
        setFilteredTasks(subtasks.map((task) => task.id))
        setUnFilteredTasks(subtasks)
      } else {
        setFilteredTasks(tasks.map((task) => task.id))
        setUnFilteredTasks(tasks)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasksLoading])

  const noFilters = filteredTasks.length === unFilteredTasks.length

  const handleSubmit = (values: ProjectUnitReportValues, { setSubmitting }: FormikHelpers<ProjectUnitReportValues>) => {
    const data = {
      ...values,
      taskReports: values.taskReports
        .filter((e) => e && (e.note || e.files || e.unitGoals)) // If there is meaningful data in any of our fields, we want to report on that task
        .map(({ files, unitGoals, ...taskReport }) => {
          const item: TaskReportInput = {
            ...taskReport,
            ...(files ? { fileIds: files.map((file) => file.fileId) } : {}),
            ...(unitGoals ? { unitGoalProgress: unitGoals?.filter((e) => e) } : {}),
          }

          return item
        }),
    }

    createManyTaskReports(data).then((result) => {
      if (result.error) {
        errorSnack("There was a problem submitting the form")
        console.error(result.error)
      } else {
        setHasUnsavedChanges(false)
        successSnack("Reported Successfully")
        handleClose()
      }

      setSubmitting(false)
    })
  }

  return (
    <Formik<ProjectUnitReportValues>
      enableReinitialize
      initialValues={{
        projectId,
        taskReports: tasks.map((task) => ({ taskId: task.id })),
      }}
      validationSchema={Yup.object().shape({
        projectId: Yup.string(),
        taskReports: Yup.array().of(
          Yup.object().shape({
            taskId: Yup.string(),
            note: Yup.string(),
            files: Yup.array(
              Yup.object().shape({
                uploaded: Yup.bool().required().oneOf([true], "Files must finish uploading"),
                fileId: Yup.string(),
              })
            ),
            unitGoals: Yup.array(
              Yup.object().shape({
                unitGoalId: Yup.string(),
                progress: Yup.string(),
              })
            ),
          })
        ),
      })}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, dirty }) => {
        setTimeout(
          () =>
            setIsDirty((previous) => {
              if (previous !== dirty) {
                setHasUnsavedChanges(dirty)
                return dirty
              }
              return previous
            }),
          0
        )

        return (
          <Form>
            <div
              style={{
                height: "100vh",
                display: "grid",
                gridTemplateRows: "60px 1fr 80px",
              }}
            >
              <DrawerHeader />
              <div className="overflow-y-scroll px-7 pt-10">
                <div className="flex gap-x-2 pb-0">
                  <Typography variant="h4" fontSize={32} className="font-bold">
                    Report Units
                  </Typography>
                  <Typography variant="h4" fontSize={32} className="font-bold text-gray-500">
                    {taskData?.project?.name || ""}
                  </Typography>
                </div>
                <Divider className="mt-1 mb-6" />
                <FilterDropdownButton<GetTasksForProjectReportingQuery["tasks"][0]>
                  unfiltered={unFilteredTasks}
                  filtered={filteredTasks}
                  setFiltered={setFilteredTasks}
                  filterBy={(task: GetTasksForProjectReportingQuery["tasks"][0]) => task.id}
                />

                <div className="max-w-[466px] flex flex-col gap-4 pt-6">
                  {taskId
                    ? subtasks.map((task, i) => (
                        <TaskReport
                          contractDeliverableUnits={taskData?.project?.contract?.deliverableUnits}
                          key={task.id}
                          task={task}
                          taskIndex={i}
                          isFilteredIn={!filteredTasks.includes(task.id)}
                          noFilters={noFilters}
                        />
                      ))
                    : tasks.map((task, i) => (
                        <TaskReport
                          contractDeliverableUnits={taskData?.project?.contract?.deliverableUnits}
                          key={task.id}
                          task={task}
                          taskIndex={i}
                          isFilteredIn={!filteredTasks.includes(task.id)}
                          noFilters={noFilters}
                        />
                      ))}
                </div>
              </div>

              {/* Footer */}
              <DrawerFooter>
                <div className="flex items-center gap-3 px-7">
                  <Button type="submit" color="primary" size="large" variant="contained" disabled={isSubmitting}>
                    Submit
                  </Button>
                  <Button variant="text" size="large" type="button" onClick={handleClose}>
                    Cancel
                  </Button>
                  {dirty && (
                    <Typography className="text-red-500" fontSize={12} fontWeight={400}>
                      You have unsaved changes
                    </Typography>
                  )}
                </div>
              </DrawerFooter>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

const TaskReport: FC<{
  contractDeliverableUnits?: { deliverableUnitId: string; customerDescription?: string | null }[]
  isFilteredIn: boolean
  taskIndex: number
  task: GetTasksForProjectReportingQuery["tasks"][0]
  noFilters: boolean
}> = ({ contractDeliverableUnits, isFilteredIn, taskIndex, task, noFilters }) => {
  const [, , { setValue: setTaskId }] = useField(`taskReports[${taskIndex}].taskId`)
  const [, , { setValue: setNote }] = useField(`taskReports[${taskIndex}].note`)
  const [showNoteField, setShowNoteField] = useState(false)
  const [showPhotoField, setShowPhotoField] = useState(false)
  const { setFieldValue } = useFormikContext()

  // If we are filtered out, do not render. Also if there are no filters then render all tasks
  if (!isFilteredIn && !noFilters) return null

  const unitGoals = (task.unitGoals || []).sort((a, b) => {
    if (a.isPrimary && !b.isPrimary) return -1
    if (!a.isPrimary && b.isPrimary) return 1
    return 0
  })

  const contractDeliverableUnitMap =
    contractDeliverableUnits?.reduce(
      (
        acc: {
          [key: string]: string
        },
        unit: (typeof contractDeliverableUnits)[0]
      ) => ({
        ...acc,
        [unit.deliverableUnitId]: unit.customerDescription ?? "",
      }),
      {}
    ) || {}

  return (
    <div className="border border-gray-200 rounded-lg p-6" key={task.id} test-label={testLabel_ReportUnitCard}>
      <Typography variant="subtitle2" className="font-bold uppercase mb-10" color="primary">
        {task.group && <span className="text-gray-400">{task.group.name} / </span>}
        {task.name}
      </Typography>

      {unitGoals.map((unitGoal, unitGoalIndex) => (
        <UnitGoalRow
          contractDeliverableUnitCustomerDescription={contractDeliverableUnitMap[unitGoal.deliverableUnit.id]}
          key={unitGoal.id}
          unitGoalFieldName={`taskReports[${taskIndex}].unitGoals[${unitGoalIndex}].unitGoalId`}
          progressFieldName={`taskReports[${taskIndex}].unitGoals[${unitGoalIndex}].progress`}
          onChange={() => setTaskId(task.id)}
          unitGoal={unitGoal}
        />
      ))}

      <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
        <Button
          sx={{ marginBottom: showNoteField ? 3 : 0 }}
          className="px-4"
          color="secondary"
          variant="contained"
          startIcon={showNoteField ? <BiX /> : <BiNote />}
          onClick={() => {
            setShowNoteField(!showNoteField)
            if (showNoteField) setNote("")
          }}
          fullWidth={true}
        >
          {showNoteField ? "Remove note" : "Add note"}
        </Button>
        <Button
          sx={{ marginBottom: showPhotoField ? 3 : 0 }}
          className="px-4"
          color="secondary"
          variant="contained"
          startIcon={showPhotoField ? <BiX /> : <BiImage />}
          onClick={() => {
            setShowPhotoField(!showPhotoField)
            if (showPhotoField) {
              setFieldValue(`taskReports[${taskIndex}].files`, [])
            }
          }}
          fullWidth={true}
        >
          {showPhotoField ? "Remove photo(s)" : "Add photo(s)"}
        </Button>
      </Box>

      {showNoteField && (
        <TextField
          multiline
          fullWidth
          label="Notes"
          name={`taskReports[${taskIndex}].note`}
          required={false}
          rows={2}
          onChange={() => setTaskId(task.id)}
        />
      )}

      {showPhotoField && (
        <ImageUploadPreviewList
          name={`taskReports[${taskIndex}].files`}
          disabled={false}
          prefix={taskReportImagePrefix(task.id)}
          onChange={() => setTaskId(task.id)}
        />
      )}
    </div>
  )
}
