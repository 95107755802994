import { clsx } from "clsx"
import { FC } from "react"
import { BiBlock } from "react-icons/bi"
import { BsFillCheckCircleFill } from "react-icons/bs"
import { gray, green, orange, sky, teal } from "tailwindcss/colors"

const getCompleteColorStyle = (progress: number) => {
  if (progress > 100) {
    return orange[600]
  }

  return green[600]
}

const getColorStyle = (progress: number, color: "green" | "blue" | "gray") => {
  if (progress > 100) {
    return orange[600]
  }

  if (color === "green") {
    return teal[600]
  }

  if (color === "gray") {
    return gray[800]
  }

  return sky[500]
}

export const PieProgressIndicator: FC<{
  progress: number
  color: "green" | "blue" | "gray"
  hasUnits?: boolean
  completed?: boolean
  className?: string
  sizeLarge?: boolean
  sizeMedium?: boolean
}> = ({ progress, color, hasUnits = true, completed = false, className, sizeLarge = false, sizeMedium = false }) => {
  const colorStyle: string = getColorStyle(progress, color)
  const pieProgress = progress > 0 && progress <= 10 ? 10 : progress

  if (completed) {
    const completeColorStyle = getCompleteColorStyle(progress)
    return (
      <BsFillCheckCircleFill
        className={clsx(className, "size-[16px] ml-[2px]")}
        style={{ color: completeColorStyle }}
      />
    )
  }

  if (!hasUnits) {
    return <BiBlock className={clsx(className, "text-gray-500 size-[16px] ml-[8px]")} />
  }

  return (
    <div
      className={className}
      style={{
        width: sizeLarge ? 28 : sizeMedium ? 24 : 16,
        height: sizeLarge ? 28 : sizeMedium ? 24 : 16,
        borderRadius: "50%",
        border: sizeLarge ? `3px solid ${colorStyle}` : `2px solid ${colorStyle}`,
        position: "relative",
        overflow: "hidden",
        background: "white",
        marginLeft: "2px",
      }}
    >
      <div
        style={{
          width: sizeLarge ? 22 : sizeMedium ? 20 : 12,
          height: sizeLarge ? 22 : sizeMedium ? 20 : 12,
          borderRadius: "50%",
          background: `conic-gradient(${colorStyle} ${pieProgress}%, white 0%)`,
          border: "2px solid white",
        }}
      />
    </div>
  )
}
