import { clsx } from "clsx"
import Head from "next/head"
import { useRouter } from "next/router"
import { FC, ReactNode, useState } from "react"
import { BillingStatus } from "../../graphql/generated/graphcache"
import { useOrganizationBillingStatus } from "../../hooks/useOrganizationBillingStatus"
import { DownloadMobileAppModal } from "../Modals/components/DownloadMobileAppModal"
import { useModalProps } from "../Modals/hooks/useModalProps"
import { PageTitle } from "../PageTitle"
import ErrorBoundary from "../common/ErrorBoundary"
import { BillingPastDueBanner } from "./Elements/BillingPastDueBanner"
import Header from "./Header/Header"
import Navbar from "./Navbar/Navbar"

type Props = {
  children: ReactNode
  title?: string
}

const LayoutFullHeight: FC<Props> = ({ children, title = "Admin" }) => {
  const router = useRouter()
  const isSchedulePage = router.route.includes("projects/[_project_id]/schedule")

  const [sidebarOpen, setSidebarOpen] = useState(false)
  const { organizationBillingStatus } = useOrganizationBillingStatus()
  const downloadMobileAppModalProps = useModalProps("Download Mobile App")

  return (
    <>
      <PageTitle title={title} />
      <Head>
        {process.env.NEXT_PUBLIC_IS_PRODUCTION !== "true" && (
          <link rel="shortcut icon" href="/favicon-development.ico" />
        )}
      </Head>

      {organizationBillingStatus !== BillingStatus.DisabledDueToNonPayment && (
        <div className="flex flex-col min-h-screen">
          <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          <div className="xl:pl-64 flex flex-col">
            <div className="z-10 w-full">
              <Header setSidebarOpen={setSidebarOpen} downloadMobileAppModalProps={downloadMobileAppModalProps} />
            </div>
            <main
              // This calc is a brittle solution that relies on a fixed header height. Please see the note in TeamList2.tsx
              style={{ height: "calc(100vh - 64px)" }}
              className={clsx(
                "z-0 w-full flex justify-center  mx-auto px-4 pt-8 sm:px-6 md:px-8",
                !isSchedulePage && "max-w-7xl"
              )}
            >
              <ErrorBoundary>{children}</ErrorBoundary>
            </main>
          </div>
        </div>
      )}
      <BillingPastDueBanner />
      {downloadMobileAppModalProps.isOpen && <DownloadMobileAppModal modalProps={downloadMobileAppModalProps} />}
    </>
  )
}

export default LayoutFullHeight
