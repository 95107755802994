import { Box, InputAdornment, Tooltip, Typography } from "@mui/material"
import { useField } from "formik"
import { FC, useContext } from "react"
import { GetTasksForProjectReportingQuery } from "../graphql/generated/client-types-and-hooks"
import { getFriendlyFloat } from "../helpers/numbers/getFriendlyFloat"
import { colors } from "../helpers/styles/colors"
import { OrganizationSettingsContext } from "../providers/OrganizationSettingsProvider"
import { TextField } from "./Formik/TextField"

export const UnitGoalRow: FC<{
  contractDeliverableUnitCustomerDescription?: string
  onChange?: () => void
  progressFieldName: string
  unitGoal: GetTasksForProjectReportingQuery["tasks"][0]["unitGoals"][0]
  unitGoalFieldName: string
}> = ({
  contractDeliverableUnitCustomerDescription,
  onChange = () => null,
  progressFieldName,
  unitGoal,
  unitGoalFieldName,
}) => {
  const { getUnitSymbol } = useContext(OrganizationSettingsContext)
  const [, , { setValue: setUnitGoalId }] = useField(unitGoalFieldName)
  const unitSymbol = getUnitSymbol(unitGoal.deliverableUnit.unitOfMeasure)
  return (
    <div key={unitGoal.id} className="grid grid-cols-12 gap-2 items-center">
      <div className="col-span-7 mb-5">
        <div className="flex gap-2">
          <Typography>{unitGoal.deliverableUnit.description}</Typography>
          <Typography className="text-gray-500">
            {getFriendlyFloat(unitGoal.totalProgress)} / {getFriendlyFloat(unitGoal.targetQuantity ?? 0)} {unitSymbol}
          </Typography>
        </div>
        {contractDeliverableUnitCustomerDescription && (
          <Box>
            <Tooltip title={contractDeliverableUnitCustomerDescription}>
              <Typography
                title={contractDeliverableUnitCustomerDescription}
                className="line-clamp-1"
                variant="caption"
                color={colors.gray[500]}
              >
                {contractDeliverableUnitCustomerDescription}
              </Typography>
            </Tooltip>
          </Box>
        )}
      </div>

      <div className="col-span-5">
        <TextField
          fullWidth
          className="h-14"
          label="Amount"
          size="small"
          type="number"
          onChange={() => {
            setUnitGoalId(unitGoal.id)
            onChange?.()
          }}
          name={progressFieldName}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" className="flex items-center gap-1">
                <Typography variant="caption">{unitSymbol}</Typography>
              </InputAdornment>
            ),
          }}
        />
      </div>
    </div>
  )
}
